<template>
  <a-modal
    title="创建授权车辆"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="corporation_id" v-show="false">

              <a-input v-decorator="['corporation_id']" />

            </a-form-item>
            <a-form-item label="number" v-show="false">
              <a-input v-decorator="['number']" />
            </a-form-item>
            <a-form-item label="车牌号">
              <car-plate-keyboard @change="numbersubmit" @submit="numbersubmit" style="line-height:0!important"></car-plate-keyboard>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import AAutoComplete from 'ant-design-vue/es/auto-complete/'
import { corporation_list } from '@/api/corporation'

// 表单字段
const fields = ['id', 'create_time', 'last_update', 'username', 'password', 'nickname',
  'avatar', 'role', 'email', 'cellphone', 'location', 'is_active']
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  components: {
    AAutoComplete
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      dataSource: [],
      corList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    numbersubmit (value) {
      console.log(value)
      this.form.setFieldsValue({
        number: value
      })
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
      corporation_list({ name: searchText }).then((res) => {
        const reslut = res.data.entries || []
        this.corList = reslut
        console.log('我是企业列表', reslut)
        this.dataSource = !searchText ? [] : reslut.map((item) => item.name)
      })
    },
    onSelect (value) {
      console.log(value, '我是选择的值', this.corList)
      // eslint-disable-next-line eqeqeq
      const corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id
      this.form.setFieldsValue({
        corporation_id: corporation_id
      })
      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    }
  }
}
</script>
