<template>
  <div>
    <a-result status="403" title="申请后开启" sub-title="申请后开启" v-if="!is_show">
      <template #extra>
        <!--        <a-button type="primary">
          Back Home
        </a-button>-->
      </template>
    </a-result>
    <div v-if="is_show">
      <!--    <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="规则编号">
                <a-input v-model="queryParam.id" placeholder=""/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="使用状态">
                <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                  <a-select-option value="0">全部</a-select-option>
                  <a-select-option value="1">关闭</a-select-option>
                  <a-select-option value="2">运行中</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="() => queryParam = {}">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>-->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车牌号">
                <a-input v-model="queryParam.number" placeholder=""/>
              </a-form-item>
            </a-col>
            <!--          <a-col :md="8" :sm="24">
              <a-form-item label="企业id">
                <a-input v-model="queryParam.corporation_id" placeholder=""/>
              </a-form-item>
            </a-col>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
      </div>
      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
        :showTotal="showTotal"
        :pagination="true"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看记录</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelet(record)"
              v-action:delete
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
      <create-form
        v-if="visible"
        ref="createModal"
        :visible="visible"
        :loading="confirmLoading"
        :model="mdl"
        @cancel="handleAddCancel"
        @ok="handleOk"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { car_list, car_create, car_delete } from '@/api/car'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import storage from 'store'
import { CORPORATION, IsPower } from '@/store/mutation-types'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      is_show: false,
      isPower: {},
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      showTotal: true,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '车牌号',
          dataIndex: 'number',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return car_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是行政车辆数据')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    const obj = JSON.parse(storage.get(IsPower))
    this.isPower = obj
    this.is_show = obj.allow_car
  },
  methods: {
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/car/CarInformation/' + record.id, query: { number: record.number } })
    },
    handleDelet (record) {
      car_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          const cor = JSON.parse(storage.get(CORPORATION))
          values.corporation_id = cor.id
            // 新增
          car_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }).catch((e) => {
              console.log(e, 'error')
              this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
